import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'; // dashboard
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'; // project
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'; // invoice
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'; // team
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'; // client
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'; // bid
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'; // bill
import MoreOutlinedIcon from '@mui/icons-material/MoreOutlined'; // more
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline'; // reports
import HiveOutlinedIcon from '@mui/icons-material/HiveOutlined';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { ACCOUNTING_STRATEGY, ORG_PLAN_BASIC, ORG_PLAN_ENTERPRISE } from '@/constants';

const menuItemsMain = [
    {
        title: "Dashboard",
        url: "/dashboard",
        permission: "get_projects",
        icon: GridViewOutlinedIcon
    },
    {
        title: "Projects",
        url: "/projects",
        permission: "get_projects",
        icon: WorkOutlineOutlinedIcon,
        planType: ORG_PLAN_ENTERPRISE,
    },
    {
        title: "Bid Sheets",
        url: "/bids",
        permission: "post_bids",
        icon: ArticleOutlinedIcon
    },
    {
        title: "Community",
        url: "/talent",
        icon: PersonOutlineOutlinedIcon
    },
    {
        title: "Clients",
        url: "/clients",
        permission: "put_clients",
        icon: FolderOpenOutlinedIcon
    },
    {
        title: "Bills",
        url: "/bills",
        permission: "get_bills",
        icon: CreditCardOutlinedIcon,
        requiredStrategy: ACCOUNTING_STRATEGY
    },
    {
        title: "Invoices",
        url: "/invoices",
        permission: "get_projects",
        icon: ReceiptOutlinedIcon,
        requiredStrategy: ACCOUNTING_STRATEGY
    },
    {
        title: "Connect",
        url: "https://makerscentralgroup.slack.com/",
        icon: HiveOutlinedIcon,
        planType: ORG_PLAN_BASIC,
    },
    {
        title: "Templates",
        url: "https://drive.google.com/drive/folders/1CiTaaX2OkwluSb0P-pXqdWbk1HCK5wMi",
        icon: DocumentScannerIcon
    },
    // {
    //     title: "Reports",
    //     url: "/reports",
    //     permission: "get_reports",
    //     icon: PieChartOutlineIcon
    // }
]

const menuItemsExternal = [
    {
        title: "Contracts",
        url: "https://contracts.makers.to/",
        permission: "view_external_nav_links",
        requireMakers: true
    },
    {
        title: "Brand",
        url: "https://brand.makers.to/",
        permission: "view_external_nav_links",
        requireMakers: true
    },
    {
        title: "Wiki",
        url: "https://app.makers.to/",
        permission: "view_external_nav_links",
        requireMakers: true
    },
    {
        title: "Bid Templates",
        url: "/template-bids",
        permission: "post_bids"
    },
    {
        title: "Team",
        url: "/team",
        permission: "put_persons",
    }
]

const menuItemsOther = [
    {
        title: "Training",
        url: "https://docs.google.com/presentation/d/1Y5trwUEThE4mD-ucrwJ_JsIsgm1tuuRmNTN0-EO6tP4/edit?usp=sharing",
        planType: ORG_PLAN_BASIC,
    },
    {
        title: "Support",
        url: "https://join.slack.com/share/enQtNzE5MzE0NjgwNTU1NS1iYzk0MTAzY2NmNWYxODIwN2JlMmFkNDYzZWE5Zjc3MWI3NTEzMTcyOTNkYjI0OWRkZjExMDkxNTlkYzY1MWE1",
        planType: ORG_PLAN_BASIC,
    },
    {
        title: "Help",
        url: "https://docs.google.com/presentation/d/1Y5trwUEThE4mD-ucrwJ_JsIsgm1tuuRmNTN0-EO6tP4/edit#slide=id.g24dc6093c81_0_947",
        planType: ORG_PLAN_ENTERPRISE,
    },
    {
        title: "Admin",
        url: "/admin",
        permission: "view_admin_panel",
    },
    {
        title: "⚠️ Super Admin ⚠️",
        url: "/super-admin",
        requireSuperAdmin: true,
    },
]

const menuItemsUser = [
    {
        title: "My Profile",
        url: "/profile",
    },
    {
        title: "Manage Account",
        url: "/account",
    },
    {
        title: "Logout",
        url: "/logout",
    },
] 

const menus = [
    {
        title: "More",
        items: menuItemsExternal,
        icon: MoreOutlinedIcon,
        
    },
    {
        title: "Help",
        items: menuItemsOther,
        icon: SupportOutlinedIcon
    }
]

const getActiveMenuItems = (token, items) => {
    const strategiesRequirementMet = item => !item.requiredStrategy || token.strategies?.includes(item.requiredStrategy)
    const isMenuItemAllowed = item => !item.permission || token.permissions.includes(item.permission)
    const showForMakers = item => !item.requireMakers || item.requireMakers == token.isMakers
    const showForPlan = item => !item.planType || item.planType == token.planType
    const showForSuperAdmin = item => !item.requireSuperAdmin || token.isSuperAdmin

    const filteredItems = items.filter(isMenuItemAllowed).filter(strategiesRequirementMet).filter(showForPlan).filter(showForMakers).filter(showForSuperAdmin)
    return filteredItems
}
const getMenuItemsMain = (token) => getActiveMenuItems(token, menuItemsMain)
const getMenuItemsExternal = (token) => getActiveMenuItems(token, menuItemsExternal)
const getMenuItemsOther = (token) => getActiveMenuItems(token, menuItemsOther)
const getMenus = (token) => menus.map(menu => ({
    ...menu,
    items: menu.items.filter(item => !item.permission || token.permissions.includes(item.permission))
}))
const getMenuItemsUser = (token) => getActiveMenuItems(token, menuItemsUser)

export { getMenuItemsMain, getMenuItemsExternal, getMenuItemsOther, getMenus, getMenuItemsUser }

import { useSelector } from 'react-redux';

import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';

import { formattedCurrency } from "@/utils";
import { selectInsightGoals, selectInsights } from "@/bid/bidSlice";
import StrategyComponents from '@/common/StrategyComponents';
import { ACCOUNTING_STRATEGY } from '@/constants';
import { selectIsMakers } from "@/auth/authSlice";


const Insight = (props) => {
    const { percentage, goal, value, currency, title, theme, formula } = props
    const isMakers = useSelector(selectIsMakers)

    return (
        <Tooltip 
            title={
                <div style={{textAlign: "right"}}>
                    <span>{formula}</span>
                    {isMakers && (
                        <>
                            <br/>
                            <span>{`Target: ${Math.round(goal*100)}%`}</span>
                        </>
                    )}
                </div>
            } 
            placement="left"
        >
            <Grid
                container
                spacing={1}
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{borderBottom: "1px solid #ffffff30", marginBottom: "12px", paddingBottom: "6px"}}
            >
                {isMakers ? (
                    <Grid item>
                        { percentage < goal ? (
                            <ErrorIcon color="warning" sx={{position: "relative", top: "-2px"}} />
                        ) : (
                            <CheckCircleIcon color="primary" sx={{position: "relative", top: "-2px"}} />
                        ) }
                    </Grid>
                ) : (
                    <Grid item>
                        <LabelImportantIcon sx={{position: "relative", top: "-2px"}} />
                    </Grid>
                )}
                <Grid item flexGrow={1}>
                    {`${(percentage*100).toFixed(1)}% ${title}`}
                </Grid>
                <Grid item sx={{color: theme.palette.text.secondary}}>
                    {formattedCurrency(value, currency)}
                </Grid>
            </Grid>
        </Tooltip>
    )
}

export default function BidInsights(props){
    const { currency } = props
    const insights = useSelector(selectInsights) 
    const goals = useSelector(selectInsightGoals) 
    const theme = useTheme()

    return (
        <Grid item style={{ display: 'flex' }} flexGrow={1}>
            <div style={{ background: theme.palette.background.paper, height: '100%', width: '100%', borderRadius: 20}}>
                <div style={{padding: 20}}>
                    <div style={{marginBottom: 10}}>Insights</div>
                    <Insight
                        title="Producer Fee Portion"
                        formula="Producer Fee / Quoted"
                        percentage={insights.producer_fee_quoted_percentage}
                        value={insights.producer_fee_quoted}
                        goal={goals.producer_fee_quoted_goal}
                        currency={currency}
                        theme={theme}
                    />
                    <Insight
                        title="Gross Profit Margin"
                        formula="(Quoted - Hard Costs) / Quoted"
                        percentage={insights.gross_profit_margin}
                        value={insights.client_gross_profit}
                        goal={goals.gross_profit_goal}
                        currency={currency}
                        theme={theme}
                    />
                    <StrategyComponents strategy={ACCOUNTING_STRATEGY}>
                        <Insight
                            title="Net Profit Margin"
                            formula="(Quoted - Budget) / Quoted"
                            percentage={insights.net_profit_margin}
                            value={insights.client_net_profit}
                            goal={goals.net_profit_goal}
                            currency={currency}
                            theme={theme}
                        />
                    </StrategyComponents>
                </div>
            </div>
        </Grid>
    )
}
